import React, { useCallback, useEffect } from 'react';
import SingleSelect from './SingleSelect';
import MultiSelect from './MultiSelect';
import OpenEndedText from './OpenEndedText';
import RatingScale from './RatingScale/RatingScale.tsx';
import { Grid, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import {
  SurveySelectors,
  SurveyActions
} from '@walmart/survey-taker-api-js-sdk';
import { logger } from '../../../../utils/logger';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%'
  }
}));

const AnswerChoiceGrid = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentQuestion = useSelector(SurveySelectors.selectCurrentQuestion);
  const allAnswers = useSelector(SurveySelectors.selectAnswersByQuestionId);

  const questionType = currentQuestion.type;

  useEffect(() => {
    logger.info('QuestionFactory: Rendering question', {
      questionId: currentQuestion.questionId,
      type: questionType
    });
  }, [currentQuestion.questionId, questionType]);

  const updateAnswer = useCallback(
    (answer) => {
      if (Object.keys(answer).length !== 0) {
        dispatch(
          SurveyActions.updateAnswers({
            question: currentQuestion,
            answer: answer
          })
        );
      }
    },
    [dispatch, currentQuestion]
  );

  const renderAnswerSection = () => {
    switch (questionType) {
      case 'radios':
        return (
          <SingleSelect
            key={currentQuestion.questionId}
            onAnswerChange={updateAnswer}
            allAnswers={allAnswers}
          />
        );
      case 'checkboxes':
        return (
          <MultiSelect
            key={currentQuestion.questionId}
            onAnswerChange={updateAnswer}
            allAnswers={allAnswers}
          />
        );
      case 'textfield':
        return (
          //"Short Form"
          <OpenEndedText
            key={currentQuestion.questionId}
            onAnswerChange={updateAnswer}
            allAnswers={allAnswers}
            maxLength={250}
          />
        );
      case 'textarea':
        return (
          //"Long Form"
          <OpenEndedText
            key={currentQuestion.questionId}
            onAnswerChange={updateAnswer}
            allAnswers={allAnswers}
            maxLength={1000}
          />
        );
      case 'range':
        return (
          <RatingScale
            key={currentQuestion.questionId}
            onAnswerChange={updateAnswer}
            allAnswers={allAnswers}
          />
        );
      default:
        return <div>Invalid Question Type</div>;
    }
  };

  return (
    <Grid container className={classes.container}>
      {renderAnswerSection()}
    </Grid>
  );
};

AnswerChoiceGrid.propTypes = {};

export default AnswerChoiceGrid;
