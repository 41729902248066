import React, { FC, useEffect, useMemo, useState } from 'react';
import { DataTypes, SurveySelectors } from '@walmart/survey-taker-api-js-sdk';
import { useSelector } from 'react-redux';
import { Radio } from '@walmart-web/livingdesign-components';
import styles from './styles.module.css';

type Props = {
  onAnswerChange: (answer: any) => void;
  allAnswers: Record<string, any>;
};

const enum Endpoint {
  Agree = 'agree',
  Likely = 'likely',
  Satisfied = 'satisfied',
  PoorExcellent = 'poor_excellent',
  WorseBetter = 'worse_better',
  Custom = 'custom'
}

const NPS_LEFT_ENDPOINT = '0 = Not at all likely';
const NPS_RIGHT_ENDPOINT = '10 = Extremely likely';
const NPS_CENTER_ENDPOINT = '5 = Neutral';

const allLabels = {
  agree: [
    '1 = Strongly disagree',
    '2 = Disagree',
    '3 = Neutral',
    '4 = Agree',
    '5 = Strongly agree'
  ],
  likely: [
    '1 = Very unlikely',
    '2 = Unlikely',
    '3 = Neutral',
    '4 = Likely',
    '5 = Very likely'
  ],
  satisfied: [
    '1 = Very dissatisfied',
    '2 = Dissatisfied',
    '3 = Neutral',
    '4 = Satisfied',
    '5 = Very satisfied'
  ],
  poor_excellent: [
    '1 = Poor',
    '2 = Fair',
    '3 = Neutral',
    '4 = Good',
    '5 = Excellent'
  ],
  worse_better: [
    '1 = Much worse',
    '2 = Worse',
    '3 = Neutral',
    '4 = Better',
    '5 = Much better'
  ]
};

const RatingScale: FC<Props> = ({ onAnswerChange, allAnswers }) => {
  const currentQuestion = useSelector(
    SurveySelectors.selectCurrentQuestion
  ) as DataTypes.RangeQuestion;

  const [selected, setSelected] = useState(
    allAnswers[currentQuestion.questionId] ?? ''
  );
  useEffect(() => {
    onAnswerChange({ [currentQuestion.questionId]: selected });
  }, [selected, onAnswerChange, currentQuestion]);

  const labels = useMemo((): string[] => {
    const endpoint = currentQuestion.endpoint as Endpoint;
    const endpointLeft = currentQuestion.endpointLeft ?? '';
    const endpointRight = currentQuestion.endpointRight ?? '';
    let result: string[] = [];
    if (currentQuestion.isNPS) {
      // NPS Question
      result = Array.from({ length: 11 }, (_, idx) => String(idx));
      result[0] = NPS_LEFT_ENDPOINT;
      result[5] = NPS_CENTER_ENDPOINT;
      result[10] = NPS_RIGHT_ENDPOINT;
    } else if (currentQuestion.max === 11) {
      // 10 Point Question
      result = Array.from({ length: 10 }, (_, idx) => String(idx + 1));
      result[0] = `1 = ${endpointLeft}`;
      result[9] = `10 = ${endpointRight}`;
    } else if (currentQuestion.max === 5) {
      // 5 Point Question
      if (endpoint === Endpoint.Custom || !allLabels.hasOwnProperty(endpoint)) {
        result = Array.from({ length: 5 }, (_, idx) => String(idx + 1));
        result[0] = `1 = ${endpointLeft}`;
        result[4] = `5 = ${endpointRight}`;
      } else {
        result = allLabels[endpoint];
      }
    }
    return result;
  }, [currentQuestion]);

  const generateViewContent = () => {
    const offset = currentQuestion.isNPS ? 0 : 1;
    const options = Array.from({ length: labels.length }, (_, idx) => (
      <Radio
        key={String(idx + offset)}
        label={labels[idx]}
        checked={selected === String(idx + offset)}
        name={labels[idx]}
        onChange={() => setSelected(String(idx + offset))}
      />
    ));
    options.reverse();
    return options;
  };

  return <div className={styles.mainContainer}>{generateViewContent()}</div>;
};

export default RatingScale;
